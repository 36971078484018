import React from "react";
import "./GamePage.css";

const BigBass = () => {
  return (
    <main>
      <section className="bcsaus-game-page pad">
        <h1>Big Bass Mission Fishin’</h1>
        <p className="t-16">
          Big Bass Mission Fishin' is an engaging online slot from Pragmatic
          Play that revolves around a fishing theme. Featuring a colorful design
          with fishing elements like boats and tackle boxes, the game has a
          5-reel layout with multiple paylines.
        </p>
        <iframe
          title="Big Bass Mission Fishin’"
          className="bcsaus-game-page__frame"
          src="https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&amp;cur=USD&amp;websiteUrl=https%3A%2F%2Fclienthub.pragmaticplay.com%2F&amp;gcpif=2273&amp;gameSymbol=vs10bbfmission&amp;jurisdiction=99&amp;lobbyUrl=https://clienthub.pragmaticplay.com/slots/game-library/"
        ></iframe>
        <ul className="bcsaus-game-page__about">
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">About the game</h2>
            <p className="t-16">
              Key features include wild and scatter symbols, as well as a bonus
              round that triggers free spins, where players can catch fish for
              instant cash prizes and multipliers. With medium volatility, the
              game offers a balanced experience, combining frequent wins with
              the potential for larger payouts, making it a favorite among slot
              enthusiasts and fishing fans.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Theme and Graphics</h2>
            <p className="t-16">
              Big Bass Mission Fishin' immerses players in a vibrant fishing
              adventure with colorful graphics and a picturesque outdoor
              backdrop. Featuring lively animations of fishing gear, boats, and
              big bass, the game creates an engaging atmosphere. The playful
              design, complemented by a catchy soundtrack, enhances the
              experience, making players feel as if they are on an exciting
              fishing expedition.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Free Spins and Bonuses</h2>
            <p className="t-16">
              In "Big Bass Mission Fishin’," players can trigger free spins by
              landing scatter symbols, usually earning 10 to 20 spins. These
              spins may include special features like multipliers and wilds.
              Bonuses can involve collecting fish symbols for payouts or a
              fishing bonus game where players reel in prizes. Additionally,
              daily or weekly bonuses might offer free spins or in-game currency
              to enhance gameplay. 4o mini
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Volatility & RTP</h2>
            <p className="t-16">
              In "Big Bass Mission Fishin’," the game features medium
              volatility, meaning players can expect a balanced mix of smaller,
              frequent wins and larger payouts. The Return to Player (RTP) is
              typically around 96.7%, which indicates a good chance of returning
              a portion of wagers over time. This combination makes it appealing
              for both casual players and those seeking bigger rewards!
            </p>
          </li>
        </ul>
      </section>
      <section className="bcsaus-faq pad">
        <h2>F.A.Q.</h2>
        <ul className="bcsaus-faq__list">
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">How do I trigger free spins?</p>
            <p className="t-16">
              Free spins are triggered by landing a specific number of scatter
              symbols, usually fish or fishing boats.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">Are there any bonus features?</p>
            <p className="t-16">
              Yes, players can collect fish symbols for payouts, and there may
              be a fishing bonus game that offers additional prizes.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">What’s the minimum bet?</p>
            <p className="t-16">
              Minimum bets can vary by casino, so check the specific site for
              details.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">What is the volatility level?</p>
            <p className="t-16">
              The game has medium volatility, offering a mix of frequent small
              wins and occasional larger payouts.
            </p>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default BigBass;
