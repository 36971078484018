import React from "react";
import "./GamePage.css";

const NeonVillains = () => {
  return (
    <main>
      <section className="bcsaus-game-page pad">
        <h1>Neon Villains</h1>
        <p className="t-16">
          Neon Villains is an exciting online slot that plunges players into a
          vibrant, neon-lit urban landscape filled with mischievous characters.
          Featuring colorful graphics and an electronic soundtrack, the game
          offers a 5-reel layout with various paylines.
        </p>
        <iframe
          title="Neon Villains"
          className="bcsaus-game-page__frame"
          src="https://staticpff.yggdrasilgaming.com/init/launchClient.html?org=Demo&amp;currency=EUR&amp;lang=en&amp;channel=pc&amp;fullscreen=yes&amp;gameid=10287"
        ></iframe>
        <ul className="bcsaus-game-page__about">
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">About the game</h2>
            <p className="t-16">
              Players can enjoy wild symbols, scatters, and unique character
              bonuses that trigger rewards and multipliers. With medium
              volatility, Neon Villains balances frequent wins with the
              potential for larger payouts, providing a thrilling experience for
              fans of dynamic gameplay.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Theme and Graphics</h2>
            <p className="t-16">
              Neon Villains features a vibrant urban theme set in a neon-lit
              cityscape, filled with colorful, cartoonish characters that exude
              a playful vibe. The bold colors and dynamic animations create an
              engaging visual experience, enhanced by neon signs and an upbeat
              electronic soundtrack. Together, these elements create a
              captivating environment that keeps players entertained as they
              explore the world of Neon Villains.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Free Spins and Bonuses</h2>
            <p className="t-16">
              Neon Villains features exciting free spins triggered by specific
              scatter symbols, allowing players to spin without wagering their
              own money. Additionally, the game includes unique character
              bonuses that activate special rewards, multipliers, and extra wild
              symbols. This combination of free spins and dynamic bonuses
              enhances gameplay and increases winning potential, keeping players
              engaged throughout.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Volatility & RTP</h2>
            <p className="t-16">
              Neon Villains has medium volatility, offering a balanced gameplay
              experience with a mix of frequent wins and the potential for
              larger payouts. The game features a Return to Player (RTP) of
              approximately 95.5%, indicating that players can expect to receive
              about 95.5% of their total bets back over time. This combination
              of medium volatility and a solid RTP makes Neon Villains appealing
              to both casual players and those seeking bigger rewards.
            </p>
          </li>
        </ul>
      </section>
      <section className="bcsaus-faq pad">
        <h2>F.A.Q.</h2>
        <ul className="bcsaus-faq__list">
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">Does Neon Villains have free spins?</p>
            <p className="t-16">
              Yes, players can trigger free spins by landing specific scatter
              symbols, allowing for additional spins without wagering their own
              money.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              What are the bonus features in Neon Villains?
            </p>
            <p className="t-16">
              The game includes unique character bonuses that can activate
              special rewards, multipliers, and extra wild symbols, enhancing
              gameplay and winning potential.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              What is the maximum win in Neon Villains?
            </p>
            <p className="t-16">
              Players can win significant payouts, with the maximum potential
              reaching up to 5,000 times their stake.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              Are there wild symbols in Neon Villains?
            </p>
            <p className="t-16">
              Yes, the game features wild symbols that can substitute for other
              symbols to help create winning combinations.
            </p>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default NeonVillains;
