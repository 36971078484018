import React from "react";
import "./GamePage.css";

const Reactoonz = () => {
  return (
    <main>
      <section className="bcsaus-game-page pad">
        <h1>Reactoonz</h1>
        <p className="t-16">
          Reactoonz is an online slot game developed by Play'n GO, featuring
          their distinctive 7x7 grid layout. Winning combinations are achieved
          by matching clusters of symbols, with a minimum bet set at 0.20. The
          game offers a range of bonuses and features, such as additional wilds
          and symbol removals. You can enjoy playing the Reactoonz slot right
          here at Slots Temple.
        </p>
        <iframe
          title="Reactoonz"
          className="bcsaus-game-page__frame"
          src="https://asccw.playngonetwork.com/casino/PlayMobile?pid=2&amp;gid=reactoonzmobile&amp;lang=en_GB&amp;server=&amp;practice=1"
        ></iframe>
        <ul className="bcsaus-game-page__about">
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">About the game</h2>
            <p className="t-16">
              Reactoonz is an engaging online slot by Play'n GO, featuring a
              colorful outer space theme and a cast of adorable alien
              characters. Instead of traditional paylines, the game uses a
              cluster mechanic, allowing players to win by matching symbols in
              groups.
              <br></br>Packed with innovative features like wild symbols,
              multipliers, and the Quantum Leap modifier, Reactoonz offers high
              volatility and the potential for significant payouts, with a
              maximum win of up to 4,570 times the stake. Its vibrant graphics
              and catchy soundtrack make it a favorite among slot enthusiasts.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Theme and Graphics</h2>
            <p className="t-16">
              Reactoonz features a playful and vibrant theme centered around
              cute alien characters. Set in a colorful outer space environment,
              the game showcases a variety of quirky symbols, each with its own
              personality. The bright colors and whimsical designs create an
              engaging atmosphere, while dynamic animations bring the characters
              to life during gameplay. The overall visual appeal is complemented
              by a catchy soundtrack, enhancing the fun and excitement as
              players spin the reels. This charming combination of graphics and
              theme makes Reactoonz a captivating experience for players.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Free Spins and Bonuses</h2>
            <p className="t-16">
              While Reactoonz lacks a traditional free spins round, it offers
              exciting bonuses that enhance gameplay. Players can benefit from
              Wild symbols that substitute for others, multipliers that boost
              payouts, and the Quantum Leap feature, which activates special
              modifiers. Additionally, the Gargantoon can drop a giant wild
              symbol onto the reels, significantly increasing winning potential.
              These unique features keep the action lively and provide great
              opportunities for big wins.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Volatility & RTP</h2>
            <p className="t-16">
              The RTP (return to player) of Reactoonz is 96.51%. It is also a
              high volatility slot.
            </p>
          </li>
        </ul>
      </section>
      <section className="bcsaus-faq pad">
        <h2>F.A.Q.</h2>
        <ul className="bcsaus-faq__list">
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">Does Reactoonz feature free spins?</p>
            <p className="t-16">
              While Reactoonz doesn’t include a traditional free spins bonus
              round, it offers a variety of innovative bonuses that more than
              compensate for it. Features like wilds, multipliers, Quantum Leap,
              and Gargantoon can enhance your winnings significantly.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">How volatile is Reactoonz?</p>
            <p className="t-16">
              Reactoonz is classified as a highly volatile online slot game.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              What is the maximum win possible in Reactoonz?
            </p>
            <p className="t-16">
              The highest payout available in Reactoonz can reach up to 4,570
              times the player’s stake. Reactoonz 2 offers a slightly higher max
              win of just over 5,000x, while Dr Toonz boasts an impressive
              22,000x payout.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              How does the bonus round function in Reactoonz?
            </p>
            <p className="t-16">
              In Reactoonz, winning symbols are collected in the Reactoonz
              Meter, located on the left side of the reels. Once you gather 25
              symbols, the energy is transferred to the Quantum Meter, allowing
              you to activate special modifiers that enhance your winning
              potential.
            </p>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default Reactoonz;
