import React from "react";
import "./GamePage.css";

const Phoenix = () => {
  return (
    <main>
      <section className="bcsaus-game-page pad">
        <h1>Phoenix up cash</h1>
        <p className="t-16">
          Phoenix Up Cash is an online slot developed by Relax Gaming and
          Trigger Button. The bonus features include free spins, instant cash
          wins, and multipliers. The base RTP of the slot is set at 95.01%, and
          the medium volatility gameplay offers a maximum win of over 10,000
          times the bet.
        </p>
        <iframe
          title="Phoenix up cash"
          className="bcsaus-game-page__frame"
          src="https://d2drhksbtcqozo.cloudfront.net/casino/apex/layer/?channel=mobile&amp;gameid=phoenixupcash&amp;moneymode=fun&amp;jurisdiction=MT&amp;partnerid=1&amp;&amp;apex=1&amp;gameurl=https%3A%2F%2Fd2drhksbtcqozo.cloudfront.net%2Fcasino%2Flauncher.html"
        ></iframe>
        <ul className="bcsaus-game-page__about">
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">About the game</h2>
            <p className="t-16">
              Phoenix Up Cash is an enchanting online slot from Relax Gaming
              that invites players on a mythical adventure. Set against a
              dramatic, dark backdrop, this game features a captivating
              4-payline format. With its unique design and thrilling gameplay,
              it has quickly become a favorite among players, offering exciting
              opportunities for big wins and free spins.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Theme and Graphics</h2>
            <p className="t-16">
              Phoenix Up Cash immerses players in the world of ancient
              mythology, with the majestic phoenix as its central element. The
              dark and mysterious background creates an atmosphere of intrigue,
              while the vibrant and detailed symbols add a playful touch.
              Dynamic animations and visual effects highlight the fiery nature
              of the phoenix, making the gameplay both exciting and captivating.
              This stylish graphics and engaging theme make the game a true
              visual delight.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Free Spins and Bonuses</h2>
            <p className="t-16">
              Phoenix Up Cash offers thrilling free spins and bonuses that
              enhance gameplay. Triggered by specific symbols, the free spins
              allow players to spin without wagering their own money, increasing
              winning potential. The game also features Wild symbols that
              substitute for others, as well as multipliers that can
              significantly boost winnings. With these exciting elements,
              Phoenix Up Cash keeps the action lively and rewarding.
            </p>
          </li>
          <li className="bcsaus-game-page__item">
            <h2 className="text-left">Volatility & RTP</h2>
            <p className="t-16">
              Phoenix Up Cash features medium volatility, offering a balanced
              gameplay experience where players can expect a mix of frequent
              small wins and the potential for larger payouts. The game has a
              base RTP (Return to Player) of 95.01%, which indicates that
              players can expect to receive approximately 95.01% of their total
              bets back over time. This combination of medium volatility and a
              solid RTP makes Phoenix Up Cash appealing to both casual players
              and those seeking bigger rewards.
            </p>
          </li>
        </ul>
      </section>
      <section className="bcsaus-faq pad">
        <h2>F.A.Q.</h2>
        <ul className="bcsaus-faq__list">
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              Are there free spins in Phoenix Up Cash?
            </p>
            <p className="t-16">
              Yes, Phoenix Up Cash features a free spins option triggered by
              specific symbols. Players can enjoy free spins that increase their
              chances of winning without wagering their own money.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              What are the bonus features in Phoenix Up Cash?
            </p>
            <p className="t-16">
              The game includes exciting bonuses such as Wild symbols,
              multipliers, and the ability to trigger instant cash wins. These
              features enhance gameplay and provide additional winning
              opportunities.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              What is the maximum win in Phoenix Up Cash?
            </p>
            <p className="t-16">
              Players can win up to 10,000 times their stake, making it an
              exciting option for those looking for significant rewards.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              Are there Wild symbols in Phoenix Up Cash?
            </p>
            <p className="t-16">
              Yes, Wild symbols are present in the game and can substitute for
              other symbols to help create winning combinations, enhancing your
              chances of winning.
            </p>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default Phoenix;
