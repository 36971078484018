import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="bcsaus-popup-cookie">
        <form className="bcsaus-popup__form">
          <p className="text-center t-32">Cookies policy</p>
          <p className="text-center t-16">
            We use cookies to enhance the functionality of our website and
            provide you with personalized content. By continuing to use this
            site, you agree to our use of cookies and our data processing
            policy.
          </p>
          <div className="bcsaus-popup__btn-container">
            <a href="/cookies" className="bcsaus-popup__btn-cookie col-pink">
              Additional information
            </a>
            <p
              className="bcsaus-popup__btn-cookie bcsaus-popup__btn-agree"
              onClick={addPopupCookie}
            >
              Confirm
            </p>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
