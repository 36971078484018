import React from "react";
import "./Main.css";
import Casinos from "../Casinos/Casinos";
import plus from "../../images/plus.svg";
import minus from "../../images/minus.svg";

// import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="bcsaus-main">
      {/* <MainPopup/> */}
      <section className="bcsaus-main__cover">
        <h1 className="bcsaus-main__header">
          Enjoy the best gaming experience in a social casino
        </h1>
      </section>
      <section className="bcsaus-about pad">
        <h2>About us</h2>
        <div className="bcsaus-about__container">
          <div className="bcsaus-about__text">
            <p className="t-16">
              Welcome to the world of social casinos — a unique space where
              excitement and community come together! We strive to create not
              just a gaming platform, but a community where every player can
              enjoy a risk-free and free gaming experience.
            </p>
            <p className="t-16">
              We take pride in our extensive experience working with leading
              providers in the social casino industry. Our team has conducted
              thorough research and analysis of various platforms to ensure you
              have the best gaming experience. We know what to look for and are
              ready to share our knowledge to help you choose the perfect place
              to play.
            </p>
          </div>
          <p className="t-16 bcsaus-about__text">
            Join us on this thrilling journey and discover all the delights of
            social casinos. Here, you have the opportunity not only to win but
            also to make new friends, share experiences, and simply enjoy the
            atmosphere of fun and excitement.
          </p>
        </div>
      </section>

      <section className="bcsaus-why-us pad">
        <h2>What is a social casino?</h2>
        <div className="bcsaus-about__container">
          <p className="bcsaus-about__text t-16">
            Social casinos are becoming increasingly popular, and for good
            reason. Unlike traditional casinos, they offer players the chance to
            have fun without worrying about losses. Here, you’ll find a wide
            variety of engaging games, from classic slots to thrilling card
            games, allowing you to develop your skills and strategies without
            risking real money.
          </p>
          <div className="bcsaus-why-us__img"></div>
        </div>
      </section>
      <Casinos />
      <section className="bcsaus-history">
        <div className="bcsaus-history__img"></div>
        <div className="bcsaus-history__text-container">
          <h2 className="bcsaus-history__header">History of Social Casinos</h2>
          <p className="t-16">
            Social casinos emerged in the early 2010s with the rise of mobile
            technology and social networks. They combined gaming with social
            interactions, allowing players to enjoy games while also
            communicating and participating in tournaments. With each passing
            year, the number of users and games grew, transforming social
            casinos into entertainment hubs that continue to evolve and attract
            millions of players worldwide.
          </p>
        </div>
      </section>
      <section className="bcsaus-features">
        <div className="bcsaus-features__body pad">
          <h2>Features of Social Casinos</h2>
          <ul className="bcsaus-features__list">
            <li className="bcsaus-features__item">
              Free Games: Players can enjoy gambling without the risk of losses,
              as all games are free.
            </li>
            <li className="bcsaus-features__item">
              Variety of Games: A wide selection of games — from slots to card
              games — allows everyone to find something they enjoy.
            </li>
            <li className="bcsaus-features__item">
              Regular Promotions: Participation in bonuses and promotions makes
              gaming even more exciting.
            </li>
            <li className="bcsaus-features__item">
              Social Interaction: Platforms offer the opportunity to chat, share
              strategies, and participate in tournaments with other players.
            </li>
            <li className="bcsaus-features__item">
              Accessibility: You can play on various devices — computers,
              tablets, and mobile phones.
            </li>
          </ul>
        </div>
      </section>
      <section className="bcsaus-perks pad">
        <h2>Pros and Cons of Social Casinos</h2>
        <div className="bcsaus-perks__container">
          <div className="bcsaus-perks__part">
            <p className="bcsaus-perks__small-header t-24">
              <img src={plus} title="plus" alt="plus" width="14" height="14" />
              Pros
            </p>
            <ul className="bcsaus-perks__list">
              <li className="bcsaus-perks__item t-16 col-pink">
                Free Games:{" "}
                <span className="col-white">
                  Players can enjoy games without the risk of losing real money.
                </span>
              </li>
              <li className="bcsaus-perks__item t-16 col-pink">
                Social Interaction:{" "}
                <span className="col-white">
                  Opportunity to chat and participate in tournaments with other
                  players.
                </span>
              </li>
              <li className="bcsaus-perks__item t-16 col-pink">
                Accessibility:{" "}
                <span className="col-white">
                  Games are available on various devices — computers and mobile
                  phones.
                </span>
              </li>
              <li className="bcsaus-perks__item t-16 col-pink">
                Variety:{" "}
                <span className="col-white">
                  A wide selection of games to suit every taste.
                </span>
              </li>
              <li className="bcsaus-perks__item t-16 col-pink">
                Regular Promotions:{" "}
                <span className="col-white">
                  Chance to participate in bonuses and special offers.
                </span>
              </li>
            </ul>
          </div>
          <div className="bcsaus-perks__part">
            <p className="bcsaus-perks__small-header t-24">
              <img
                src={minus}
                title="minus"
                alt="minus"
                width="14"
                height="14"
              />
              Cons
            </p>
            <ul className="bcsaus-perks__list">
              <li className="bcsaus-perks__item t-16 col-pink">
                No Real Winnings:{" "}
                <span className="col-white">
                  Games do not offer the chance to win real money.
                </span>
              </li>
              <li className="bcsaus-perks__item t-16 col-pink">
                Internet Dependency:{" "}
                <span className="col-white">
                  Games can only be played with an internet connection.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bcsaus-faq pad">
        <h2>F.A.Q.</h2>
        <ul className="bcsaus-faq__list">
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              Can I win real money in social casinos?
            </p>
            <p className="t-16">
              No, social casinos operate with virtual currencies, and it is not
              possible to win real money.
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              Do I have to pay for social casino games?
            </p>
            <p className="t-16">
              Most social casinos offer free games, but there may be options to
              purchase additional chips or bonuses
            </p>
          </li>
          <li className="bcsaus-faq__item">
            <p className="t-24 col-pink">
              Are social casinos suitable for beginners?
            </p>
            <p className="t-16">
              Yes, social casinos are ideal for beginners, as you can learn the
              game rules without financial risks.
            </p>
          </li>
        </ul>
      </section>
      <section className="bcsaus-conclusion">
        <h2 className="hidden">Conclusie:</h2>
        <p className="t-24 bcsaus-conclusion__text text-center">
          Social online casinos are becoming increasingly popular as a leisure
          activity in Germany. With their safe environment and live gaming
          experience, they provide a home for gambling enthusiasts. Thanks to
          free spins and licensed providers, players can feel relaxed and
          secure. We wish you great success in exploring this new direction in
          the gambling industry!
        </p>
      </section>
    </main>
  );
};

export default MainCover;
