import React, { useState } from "react";
import "./Casinos.css";
import gameOne from "../../images/reactoonz.webp";
import gameTwo from "../../images/phoenix.webp";
import gameThree from "../../images/neon-villains.webp";
import gameFour from "../../images/big-bass.webp";
import closeBtn from "../../images/close-btn.svg";
import { Link } from "react-router-dom";
import star from "../../images/star.svg";

const Casinos = () => {
  const [isGameOneVisible, setIsGameOneVisible] = useState(false);
  const [isGameTwoVisible, setIsGameTwoVisible] = useState(false);
  const [isGameThreeVisible, setIsGameThreeVisible] = useState(false);
  const [isGameFourVisible, setIsGameFourVisible] = useState(false);

  return (
    <section className="bcsaus-casinos pad">
      <h2>Your adventure begins here!</h2>
      <ul className="bcsaus-casinos__grid">
        <li className="bcsaus-casinos__game">
          <div className="bcsaus-casinos__img-container">
            <img
              src={gameOne}
              title="Reactoonz"
              alt="Reactoonz"
              width="276"
              height="231"
              className="bcsaus-casinos__game-cover"
            />
            <div className="bcsaus-casinos__overlay">
              <p className="t-16 bcsaus-casinos__rating">
                <img src={star} title="star" alt="star" />
                3,6
              </p>
              <p
                className="btn bcsaus-casinos__play t-24"
                onClick={() => setIsGameOneVisible(true)}
              >
                Play now
              </p>
              <Link
                to="/reactoonz"
                className="btn bcsaus-casinos__more-info col-pink t-24"
              >
                Read description
              </Link>
            </div>
          </div>
          <p className="t-32 text-center">Reactoonz</p>
        </li>
        <li className="bcsaus-casinos__game">
          <div className="bcsaus-casinos__img-container">
            <img
              src={gameTwo}
              title="Phoenix up cash"
              alt="Phoenix up cash"
              width="276"
              height="231"
              className="bcsaus-casinos__game-cover"
            />
            <div className="bcsaus-casinos__overlay">
              <p className="t-16 bcsaus-casinos__rating">
                <img src={star} title="star" alt="star" />
                2,6
              </p>
              <p
                className="btn bcsaus-casinos__play t-24"
                onClick={() => setIsGameTwoVisible(true)}
              >
                Play now
              </p>
              <Link
                to="/phoenix-up-cash"
                className="btn bcsaus-casinos__more-info col-pink t-24"
              >
                Read description
              </Link>
            </div>
          </div>
          <p className="t-32 text-center">Phoenix up cash</p>
        </li>
        <li className="bcsaus-casinos__game">
          <div className="bcsaus-casinos__img-container">
            <img
              src={gameThree}
              title="Neon Villains"
              alt="Neon Villains"
              width="276"
              height="231"
              className="bcsaus-casinos__game-cover"
            />
            <div className="bcsaus-casinos__overlay">
              <p className="t-16 bcsaus-casinos__rating">
                <img src={star} title="star" alt="star" />
                3,5
              </p>
              <p
                className="btn bcsaus-casinos__play t-24"
                onClick={() => setIsGameThreeVisible(true)}
              >
                Play now
              </p>
              <Link
                to="/neon-villains"
                className="btn bcsaus-casinos__more-info col-pink t-24"
              >
                Read description
              </Link>
            </div>
          </div>
          <p className="t-32 text-center">Neon Villains</p>
        </li>
        <li className="bcsaus-casinos__game">
          <div className="bcsaus-casinos__img-container">
            <img
              src={gameFour}
              title="Big Bass"
              alt="Big Bass"
              width="276"
              height="231"
              className="bcsaus-casinos__game-cover"
            />
            <div className="bcsaus-casinos__overlay">
              <p className="t-16 bcsaus-casinos__rating">
                <img src={star} title="star" alt="star" />
                2,8
              </p>
              <p
                className="btn bcsaus-casinos__play t-24"
                onClick={() => setIsGameFourVisible(true)}
              >
                Play now
              </p>
              <Link
                to="/big-bass"
                className="btn bcsaus-casinos__more-info col-pink t-24"
              >
                Read description
              </Link>
            </div>
          </div>
          <p className="t-32 text-center">Big Bass</p>
        </li>
      </ul>

      {isGameOneVisible && (
        <div className="bcsaus-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="bcsaus-popup__close"
              onClick={() => setIsGameOneVisible(false)}
            />
            <iframe
              title="Reactoonz"
              className="bcsaus-game__frame"
              src="https://asccw.playngonetwork.com/casino/PlayMobile?pid=2&amp;gid=reactoonzmobile&amp;lang=en_GB&amp;server=&amp;practice=1"
            ></iframe>
          </div>
        </div>
      )}
      {isGameTwoVisible && (
        <div className="bcsaus-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="bcsaus-popup__close"
              onClick={() => setIsGameTwoVisible(false)}
            />
            <iframe
              title="Phoenix Up Cash"
              className="bcsaus-game__frame"
              src="https://d2drhksbtcqozo.cloudfront.net/casino/apex/layer/?channel=mobile&amp;gameid=phoenixupcash&amp;moneymode=fun&amp;jurisdiction=MT&amp;partnerid=1&amp;&amp;apex=1&amp;gameurl=https%3A%2F%2Fd2drhksbtcqozo.cloudfront.net%2Fcasino%2Flauncher.html"
            ></iframe>
          </div>
        </div>
      )}
      {isGameThreeVisible && (
        <div className="bcsaus-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="bcsaus-popup__close"
              onClick={() => setIsGameThreeVisible(false)}
            />
            <iframe
              title="Neon Villains"
              className="bcsaus-game__frame"
              src="https://staticpff.yggdrasilgaming.com/init/launchClient.html?org=Demo&amp;currency=EUR&amp;lang=en&amp;channel=pc&amp;fullscreen=yes&amp;gameid=10287"
            ></iframe>
          </div>
        </div>
      )}
      {isGameFourVisible && (
        <div className="bcsaus-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="bcsaus-popup__close"
              onClick={() => setIsGameFourVisible(false)}
            />
            <iframe
              title="Big Bass"
              className="bcsaus-game__frame"
              src="https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&amp;cur=USD&amp;websiteUrl=https%3A%2F%2Fclienthub.pragmaticplay.com%2F&amp;gcpif=2273&amp;gameSymbol=vs10bbfmission&amp;jurisdiction=99&amp;lobbyUrl=https://clienthub.pragmaticplay.com/slots/game-library/"
            ></iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default Casinos;
