import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import age from "../../images/age.svg";
import logo from "../../images/logo.webp";

const Footer = () => {
  return (
    <footer className="bcsaus-footer pad">
      <div className="bcsaus-footer__top">
        <img src={logo} alt="logo" title="logo" width="176" height="108" />
        <p className="t-16 bcsaus-footer__text">
          Disclaimer: All information on our website is for informational
          purposes only.
        </p>
        <p className="t-16 bcsaus-footer__text">
          Bestcasinosaus.com uses cookies to ensure you the best experience. By
          staying on the site, you agree to our use of cookies. <br></br>
          <Link to="/cookies" className="bcsaus-footer__link">
            Learn more.
          </Link>
        </p>
        <div className="bcsaus-footer__policy">
          <Link to="/privacy" className="bcsaus-footer__link">
            Privacy Policy
          </Link>
          <img src={age} title="age" alt="age" width="48" height="48" />
        </div>
      </div>
      <p className="t-16">Copyright © bestcasinosaus.com</p>
    </footer>
  );
};

export default Footer;
