import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import Reactoonz from "../GamePages/Reactoonz";
import Phoenix from "../GamePages/PhoenixUpCash";
import NeonVillains from "../GamePages/NeonVillains";
import BigBass from "../GamePages/BigBass";
import Popup from "../Popup/Popup";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";
import ScrollToTop from "../Scroll/Scroll";

function App() {
  return (
    <div className="bcsaus-page">
      <ScrollToTop />
      <Header />
      <Popup />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/reactoonz" element={<Reactoonz />} />
        <Route path="/phoenix-up-cash" element={<Phoenix />} />
        <Route path="/neon-villains" element={<NeonVillains />} />
        <Route path="/big-bass" element={<BigBass />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
