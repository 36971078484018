import { Link } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";
import logo from "../../images/logo.webp";

function Header() {
  const [isActive, setIsActive] = useState(false);

  function handleClick() {
    setIsActive(false);
  }

  return (
    <header className="bcsaus-header">
      <div className="bcsaus-header__container">
        <img
          src={logo}
          alt="logo"
          title="logo"
          className="bcsaus-header__logo"
          width="176"
          height="108"
        />
        <div className="bcsaus-header__link-container">
          <Link to="/" className="bcsaus-header__link">
            Main page
          </Link>
          <Link to="/reactoonz" className="bcsaus-header__link">
            Reactoonz
          </Link>
          <Link to="/phoenix-up-cash" className="bcsaus-header__link">
            Phoenix up cash
          </Link>
          <Link to="/neon-villains" className="bcsaus-header__link">
            Neon Villains
          </Link>
          <Link to="/big-bass" className="bcsaus-header__link">
            Big Bass
          </Link>
        </div>

        <div className="burger-menu">
          <div id="burger-menu">
            <img
              src={burgerMenu}
              alt="burger menu icon"
              className="bcsaus-burger__icon"
              onClick={() => setIsActive(true)}
            />
          </div>
          <nav
            className={`burger-menu__nav ${isActive ? "active" : ""}`}
            id="nav"
          >
            <img
              src={closeBtn}
              className="burger__close-btn"
              id="close-icon"
              alt="close menu icon"
              onClick={handleClick}
            />
            <div className="burger-menu__nav-container">
              <Link
                to="/"
                className="bcsaus-header__link"
                onClick={handleClick}
              >
                Main page
              </Link>
              <Link
                to="/reactoonz"
                className="bcsaus-header__link"
                onClick={handleClick}
              >
                Reactoonz
              </Link>
              <Link
                to="/phoenix-up-cash"
                className="bcsaus-header__link"
                onClick={handleClick}
              >
                Phoenix up cash
              </Link>
              <Link
                to="/neon-villains"
                className="bcsaus-header__link"
                onClick={handleClick}
              >
                Neon Villains
              </Link>
              <Link
                to="/big-bass"
                className="bcsaus-header__link"
                onClick={handleClick}
              >
                Big Bass
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
